import React, { PureComponent } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import BestSellersSection from '../views/home/BestSellersSection';
import Footer from '../views/default-layout/footer/Footer';
import NewArrivalSection from '../views/home/NewArrivalSection';
import CategorySection from '../views/home/CategorySection';
import SEO from '@components/SEO';
import { connect } from 'react-redux';
import { Link } from 'gatsby';
import constant from '@utils/constant';
import Skeleton from '@components/Skeleton';
import LazyImage from '@components/LazyImage';

const mainImg = '../assets/images/img-home1.png';

class Home extends PureComponent {
   state = {
      newProductName: '',
   };

   componentDidMount() {
      this.loadContent(this.props.content);
   }

   componentDidUpdate(prevProps, prevState) {
      if (prevProps.loading !== this.props.loading && !this.props.loading) {
         const content = this.props.content || [];
         if (content?.length) {
            this.loadContent(content);
         }
      }
   }

   loadContent = (contentList) => {
      this.setState({
         newProductName: contentList?.[0]?.name || '',
      });
   };

   render() {
      const { newProductName } = this.state;
      const { data: configData, loading: configLoading } = this.props.config || {};
      const { banners = [] } = configData || {};

      let displayBannerUrl = banners?.length ? banners[0]?.url || '' : '';
      return (
         <>
            <SEO />
            <div className="home">
               {/* intro  */}
               <div className="home-intro">
                  <div className="home-intro__image">
                     <Skeleton className={configLoading ? 'loading' : ''} />
                     {!configLoading && (
                        <>
                           {!displayBannerUrl ? (
                              <StaticImage
                                 className="main-img"
                                 // placeholder="blurred"
                                 placeholder="tracedSVG"
                                 src={mainImg}
                                 alt={
                                    newProductName
                                       ? `NEW ARRIVAL: ${newProductName}`
                                       : 'VAPEGANGZ'
                                 }
                              />
                           ) : (
                              <LazyImage
                                 src={displayBannerUrl}
                                 title={
                                    newProductName
                                       ? `NEW ARRIVAL: ${newProductName}`
                                       : 'VAPEGANGZ'
                                 }
                              />
                           )}
                        </>
                     )}
                  </div>
                  <div className="home-intro__content">
                     <div className="home-intro__content--text animate__animated animate__fadeInLeft">
                        {newProductName ? (
                           <>
                              <label className="text-1">NEW ARRIVAL</label>
                              <label className="text-2">{newProductName}</label>
                           </>
                        ) : (
                           <>
                              <label className="text-1">VAPEGANGZ</label>
                              <label className="text-2">
                                 STOP SMOKE <br />
                                 LET'S VAPING
                              </label>
                           </>
                        )}
                        <Link
                           to={constant.ROUTE_PRODUCTS}
                           style={{
                              width: 'max-content',
                              display: 'block',
                           }}
                        >
                           <button className="btn-white">SHOP NOW</button>
                        </Link>
                     </div>
                  </div>
               </div>

               {/* category */}
               {/* <CategorySection /> */}

               {/* NEW ARRIVALS */}
               <NewArrivalSection />

               {/* BESTSELLERS */}
               <BestSellersSection />
            </div>
            <Footer />
         </>
      );
   }
}

const mappingState = (state, ownProps) => {
   return {
      loading: state.home.newProducts.loading,
      content: state.home.newProducts.content,
      config: state.common.config,
   };
};

export default connect(mappingState)(Home);
