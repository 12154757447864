import React, { PureComponent } from 'react';

class IconStar extends PureComponent {
   render() {
      return (
         <svg
            width={this.props.size || 12}
            height={this.props.size || 12}
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
         >
            <path
               d="M11.999 0H-0.000976562V4.80872V12H11.999V4.84971C11.9896 5.02509 11.9182 5.20052 11.7737 5.34212L9.43071 7.62512L9.98346 10.8494C10.0885 11.4644 9.44121 11.9256 8.89521 11.6399L5.99946 10.1174L3.10446 11.6391C2.55396 11.9279 1.91196 11.4591 2.01621 10.8486L2.56896 7.62438L0.225962 5.34137C0.0699568 5.18905 -0.000976562 4.99736 -0.000976562 4.80872C-0.000976562 4.45866 0.243285 4.11912 0.641462 4.06112L3.87921 3.59087L5.32671 0.657625C5.45346 0.4015 5.72646 0.273438 5.99946 0.273438C6.27246 0.273438 6.54546 0.4015 6.67221 0.657625L8.12046 3.59087L11.3575 4.06112C11.7405 4.11691 11.9811 4.43318 11.999 4.76892V0Z"
               fill="#F9F9F9"
            />
            <path
               fillRule="evenodd"
               clipRule="evenodd"
               d="M7.78897 4.06523L6.22457 0.896727C6.20167 0.850676 6.13131 0.790771 5.99995 0.790771C5.86854 0.790771 5.79827 0.850527 5.7754 0.896585L4.21174 4.06523L0.714018 4.57324M5.3272 0.674959C5.45395 0.418834 5.72695 0.290771 5.99995 0.290771C6.27295 0.290771 6.54595 0.418834 6.6727 0.674959L8.12095 3.60821L11.3579 4.07846C11.9707 4.16771 12.2189 4.92371 11.7742 5.35946L9.4312 7.64246L9.98395 10.8667C10.0889 11.4817 9.4417 11.943 8.8957 11.6572L5.99995 10.1347L3.10495 11.6565C2.55445 11.9452 1.91245 11.4765 2.0167 10.866L2.56945 7.64171L0.22645 5.35871C-0.21905 4.92371 0.0292006 4.16771 0.641951 4.07846L3.8797 3.60821L5.3272 0.674959ZM0.575763 5.00096L3.10668 7.46707L2.50957 10.9501C2.47485 11.1534 2.68949 11.3094 2.87232 11.2139L5.99998 9.56983L9.12754 11.2142C9.31221 11.3106 9.52541 11.1519 9.49108 10.9509L8.89397 7.46782L11.4243 5.00231C11.5729 4.85618 11.4892 4.603 11.2861 4.57327M7.78897 4.06523L0.714018 4.57324L0.575763 5.00096L11.2861 4.57327M0.575763 5.00096C0.427539 4.85604 0.510264 4.60307 0.714018 4.57324Z"
               fill="#FFB901"
            />
         </svg>
      );
   }
}

export default IconStar;
