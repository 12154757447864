import React, { memo, PureComponent } from 'react';
import _ from 'lodash';
import IconStar from '../../components/Icons/IconStar';

class Rate extends PureComponent {
   getArrayPoint = (point) => {
      let arrPoint = [0, 0, 0, 0, 0];
      if (point) {
         let pointValue = parseFloat(point);
         for (let i = 0; i < 5; i++) {
            if (pointValue >= 1) {
               arrPoint[i] = 1;
               pointValue--;
            } else {
               arrPoint[i] = _.round(pointValue, 1);
               pointValue -= pointValue;
            }
         }
      }
      return arrPoint;
   };

   handleSelectStar = (index) => {
      if (this.props.onClick) {
         this.props.onClick(index + 1);
      }
   };

   render() {
      const { point } = this.props;
      let arrPoint = this.getArrayPoint(point);
      return (
         <div className="star-container" title={`Rating: ${point}`}>
            {arrPoint.map((point, index) => (
               <Star
                  value={point}
                  key={point + '_' + index}
                  index={index}
                  size={this.props.size}
                  onClick={this.handleSelectStar}
               />
            ))}
         </div>
      );
   }
}

class Star extends PureComponent {
   handleClick = () => {
      if (this.props.onClick) {
         this.props.onClick(this.props.index);
      }
   };

   render() {
      const { value } = this.props; // value must be <= 1
      let starPoint = 0;
      if (value) {
         starPoint = parseFloat(value) * 100;
      }

      return (
         <div className="star-rating-icon" onClick={this.handleClick}>
            <IconStar size={this.props.size} />
            <div className="star-rating-backgroud" style={{ width: `${starPoint}%` }}></div>
         </div>
      );
   }
}

export default Rate;
