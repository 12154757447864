import React, { Fragment, PureComponent } from 'react';
import { Row, Col } from 'antd';
import IconArrow2 from '../../components/Icons/IconArrow2';
import ProductItem from '../product-item/ProductItem';
import SectionTitleSkeleton from '../../components/Skeleton/skeleton-components/SectionTitleSkeleton';
import SkeletonItem from '../../components/Skeleton/SkeletonItem';
import { connect } from 'react-redux';
import { functionUtils } from '@utils/function';
import { FileSize } from '@utils/enums/FileSize';
class NewArrivalSection extends PureComponent {
   state = {
      products: [],
   };

   componentDidMount() {
      this.loadContent(this.props.content);
   }

   componentDidUpdate(prevProps, prevState) {
      if (prevProps.loading !== this.props.loading && !this.props.loading) {
         const content = this.props.content || [];
         if (content?.length) {
            this.loadContent(content);
         }
      }
   }

   loadContent = (contentList) => {
      const products = contentList.map((item) => {
         let imgUrl = '';
         if (item.attachments?.length) {
            imgUrl = item.attachments[0]?.formats?.[FileSize.SMALL] || '';
         }
         return {
            uid: item.uid,
            slug: item.slug,
            name: item.name,
            price: item.price,
            localPrice: item.local_price,
            rating: item.rate,
            reviews: item.reviews,
            imgUrl,
            status: functionUtils.getProductDisplayStatus(item),
         };
      });
      this.setState({ products });
   };

   render() {
      const { products } = this.state;
      const { loading } = this.props;
      return (
         <>
            <div className="section-title end">
               <span className="section-title-name animate__animated animate__fadeIn">
                  NEW ARRIVALS
               </span>
               <span className="section-view-all">
                  <span className="s-arrow animate__animated animate__rotateInDownRight animate__slow">
                     <IconArrow2 />
                  </span>
                  <span>VIEW ALL</span>
               </span>
            </div>
            <div className="section-content overflow-sm">
               {(loading || (!loading && products.length === 0)) && (
                  <div className="section-content__empty">
                     {products.length === 0 && <span>No Products</span>}
                  </div>
               )}
               {!loading && products.length !== 0 && (
                  <Row gutter={30}>
                     {products.map((item) => {
                        return (
                           <Col lg={6} md={6} sm={6} key={item.uid}>
                              <ProductItem
                                 slug={item.slug}
                                 imgUrl={item.imgUrl}
                                 name={item.name}
                                 price={item.price}
                                 localPrice={item.localPrice}
                                 rating={item.rating}
                                 reviews={item.reviews}
                                 status={item.status}
                              />
                           </Col>
                        );
                     })}
                  </Row>
               )}
            </div>
         </>
      );
   }
}

const mappingState = (state, ownProps) => {
   return {
      loading: state.home.newProducts.loading,
      content: state.home.newProducts.content,
   };
};

export default connect(mappingState)(NewArrivalSection);
