import React, { PureComponent } from 'react';
import Rate from './Rate';
import { Link } from 'gatsby';
import LazyImage from '@components/LazyImage';
import _snakeCase from 'lodash/snakeCase';
import Currency from '@components/Currency';

class ProductItem extends PureComponent {
   render() {
      const { slug, imgUrl, name, price, localPrice, rating, reviews, status } = this.props;
      console.log(status);
      return (
         <div className="product-card-item">
            <Link className="product-item-link" to={`/${slug}`}>
               <div className="product-card__preview">
                  <div className="mask">
                     {status && <div className={`current-status ${_snakeCase(status)}`}>{status}</div>}
                     <div className="quick-view">
                        <span>VIEW DETAIL</span>
                     </div>
                  </div>
                  <LazyImage className="product-card__img" src={imgUrl} title={name} />
               </div>
            </Link>
            <div className="card-info">
               <label className="product-name" title={name}>
                  {name}
               </label>
               <span className="price">
                  <Currency price={price} localPrice={localPrice} />
               </span>
               <span className="sub-info">
                  <Rate point={rating} />
                  <span className="count-review">{reviews} reviews</span>
               </span>
            </div>
         </div>
      );
   }
}

export default ProductItem;
