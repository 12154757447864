import classNames from 'classnames';
import React, { memo } from 'react';

const Skeleton = memo(({
   className,
}) => {
   return (
      <div className={classNames('common-skeleton', className || '')}>
      </div>
   );
});

export default Skeleton;